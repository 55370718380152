import React from 'react'

type Props = {}

const MyProfile = (props: Props) => {
  return (
    <div>MyProfile</div>
  )
}

export default MyProfile